import type { Product } from "~/schemas";
import DtwImage from "./DtwImage";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { useMainData, useTextColorForSecondaryBg } from "~/hooks";
import type { ProductJson } from "~/jsonSchemas";
import { NO_VARIATION, useCartStore } from "~/store";
import CartCounter from "./CartCounter";
import { ClientOnly } from "remix-utils/client-only";
import { Link } from "@remix-run/react";
import type { MouseEvent } from "react";
import { useEffect, useMemo, useState } from "react";
import ProductPrice from "./ProductPrice";

interface ProductCardProps {
  product: Product | ProductJson;
  className?: string;
  disableInteraction?: boolean;
}

function ProductCard({
  product,
  className,
  disableInteraction,
}: ProductCardProps) {
  const main = useMainData();
  const { categories } = main;
  const { variations } = product;
  const secondaryBgTextColor = useTextColorForSecondaryBg();

  const [variationId, setVariationId] = useState<string>("");

  const [qty, increase] = useCartStore((s) => [
    s.cart[product.id],
    s.incrementCartQty,
  ]);
  const variationInCart =
    variationId && qty ? qty[variationId] !== undefined : false;

  useEffect(() => {
    // Auto-select the variation the user has in cart, if any.
    if (!variationId && qty && Object.keys(qty).length > 0) {
      setVariationId(Object.keys(qty)[0]);
    } else if (!variations || variations.length === 0) {
      // The product does not have any variations
      setVariationId(NO_VARIATION);
    }
  }, [variations, variationId, qty]);

  const addToCart = (e: MouseEvent<HTMLButtonElement>) => {
    // Prevent the card from navigating.
    e.stopPropagation();
    e.preventDefault();

    if (!variationId) {
      return;
    }
    increase(product, variationId);
  };

  const selectedVariation = (
    e: MouseEvent<HTMLButtonElement> | SelectChangeEvent,
    vId: string
  ) => {
    // Prevent the card from navigating.
    e.stopPropagation();
    e.preventDefault();

    setVariationId(vId);
  };

  const link = useMemo(() => {
    const catId = product.categories[0].id;
    const cat = categories.find((c) => c.id === catId);
    if (!cat) {
      return "#";
    }

    return `/products${cat?.slug}/${product.slug}`;
  }, [categories, product]);

  return (
    <Card
      elevation={4}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 250,
      }}
      className={className}
    >
      <Link to={link} style={{ color: "inherit" }}>
        {/* If 320px is larger than the screen width - padding then the photo still won't be square. */}
        <div style={{ position: "relative", height: "min(250px, 90vw)" }}>
          <DtwImage
            sx={{
              height: 1,
              width: 1,
            }}
            picture={product.pictures[0]}
            alt={product.name}
          />
          {!!product.discountValue && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                display: "inline-flex",
                padding: "24px 16px",
                margin: "0 auto auto 0",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "0 50px 50px 50px",
                backgroundColor: (t) => t.palette.secondary.main,
              }}
            >
              <Typography
                variant="body1"
                style={{ color: secondaryBgTextColor }}
              >
                {product.discountValue}
              </Typography>
            </Box>
          )}
          {!disableInteraction && (
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: "#ffffffe0",
              }}
            >
              {!!product.variations && (
                <Box
                  sx={{
                    padding: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {product.variations.length <= 5 ? (
                    <ButtonGroup aria-label="Variations">
                      {product.variations.map((v) => (
                        <Button
                          key={v.id}
                          variant={
                            v.id == variationId ? "contained" : undefined
                          }
                          onClick={(e) => selectedVariation(e, v.id)}
                        >
                          {v.name}
                        </Button>
                      ))}
                    </ButtonGroup>
                  ) : (
                    <FormControl sx={{ width: 1 }} size="small">
                      <InputLabel id={`variation-label-${product.id}`}>
                        Select variation
                      </InputLabel>
                      <Select
                        id={`variation-${product.id}`}
                        labelId={`variation-label-${product.id}`}
                        label="Select variation"
                        onChange={(e) => selectedVariation(e, e.target.value)}
                        // If we let the event propagate clicking on the card will navigate to the product page.
                        onClick={(e) => e.stopPropagation()}
                        value={variationId}
                      >
                        <MenuItem value={""} key="empty">
                          Select variation
                        </MenuItem>
                        {product.variations.map((v) => (
                          <MenuItem key={v.id} value={v.id}>
                            {v.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Box>
              )}
            </div>
          )}
        </div>
        <ClientOnly
          fallback={
            <div style={{ height: "55px", gridColumn: 1, gridRow: 2 }} />
          }
        >
          {() => (
            // The fixed height helps the CartCounter and button take up the same space.
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: 55,
              }}
            >
              {!variationInCart && (
                <Tooltip
                  title={
                    variationId
                      ? undefined
                      : "Select a type to add to cart first."
                  }
                >
                  <Button
                    variant="contained"
                    sx={{ width: 1, padding: 1, margin: 1 }}
                    aria-label={`Add ${product.name} to cart`}
                    onClick={addToCart}
                  >
                    Add to cart
                  </Button>
                </Tooltip>
              )}
              {!!variationId && variationInCart && (
                <Box sx={{ marginTop: (t) => t.spacing() }}>
                  <CartCounter product={product} variationId={variationId} />
                </Box>
              )}
            </div>
          )}
        </ClientOnly>
        <Box sx={{ p: (t) => t.spacing(1) }}>
          <Typography
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            width={1}
            display="inline-block"
            overflow="hidden"
            variant="subtitle1"
          >
            {product.name}
          </Typography>

          <ProductPrice product={product} variationId={variationId} />
        </Box>
      </Link>
    </Card>
  );
}

export default styled(ProductCard)``;
